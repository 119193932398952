import countriesService from '@/api/countries-service';

const state = {
  countries: null,
};

const mutations = {
  SET_COUNTRIES(state, data) {
    state.countries = data;
  },
};

const actions = {
  fetchCountries({ commit }) {
    return countriesService.getList().then((res) => {
      commit('SET_COUNTRIES', res.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
