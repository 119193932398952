import userService from '@/api/user-service';
import eventBus, { OPEN_SNACKBAR, openConfirmDialog } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { mapErrorsToInputs } from '@/util/forms';

const state = {
  selectedContact: null,
  contactPasswordValidationErrors: {},
};

const mutations = {
  SET_SELECTED_CONTACT(state, client) {
    state.contactPasswordValidationErrors = {};
    state.selectedContact = JSON.parse(JSON.stringify(client));
  },

  SET_CONTACT_VALIDATION_ERRORS(state, validationErrors) {
    state.contactPasswordValidationErrors = validationErrors;
  },

  CLEAR_CONTACT_VALIDATION_ERRORS(state, field) {
    delete state.contactPasswordValidationErrors[field];
  },
};

const actions = {
  createLogin({ state, commit }, payload) {
    return userService
      .createLogin({
        id: state.selectedContact.id,
        pass: payload.pass,
      })
      .then((res) => {
        commit('clients/UPDATE_CLIENT_AFTER_LOGIN_CREATE', res.data.person, { root: true });
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('login_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_CONTACT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  destroyLogin({ commit }, payload) {
    openConfirmDialog({
      title: i18n.t('confirm_login_destroy'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      userService.destroyLogin(payload.contact.id).then((res) => {
        const client = { ...payload.client };
        for (let i = 0; i < payload.client.contacts.length; i++) {
          if (payload.client.contacts[i].id === res.data.person.id) {
            client.contacts[i].user = res.data.person.user;
          }
        }
        commit('clients/UPDATE_CLIENT', client, { root: true });
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('login_destroyed'));
        return res.data;
      });
    });
  },

  changePassword({ commit, state }, payload) {
    return userService
      .changeContactPassword({
        id: state.selectedContact.id,
        pass: payload.pass,
      })
      .then((res) => {
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('password_changed'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_CONTACT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  clearContactValidationErrors({ commit }, field) {
    commit('CLEAR_CONTACT_VALIDATION_ERRORS', field);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
