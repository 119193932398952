import Vue from 'vue';
import i18n from '../i18n/i18n-config';

const eventBus = new Vue();

// event types
export const OPEN_CONFIRM_DIALOG = 'open-confirm-dialog';
export const CONFIRM_DIALOG_CLOSED = 'confirm-dialog-closed';
export const OPEN_SNACKBAR = 'open-snackbar';

// helper to open confirm dialog with less code
export const openConfirmDialog = (params) => {
  eventBus.$emit(OPEN_CONFIRM_DIALOG, {
    title: params?.title || i18n.t('confirm_entry_delete'),
  });
  return new Promise((resolve) => {
    eventBus.$on(CONFIRM_DIALOG_CLOSED, async (confirmed) => {
      resolve(confirmed);
      eventBus.$off(CONFIRM_DIALOG_CLOSED);
    });
  });
};

export default eventBus;
