import productService from '@/api/products-service';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import eventBus, { OPEN_SNACKBAR, openConfirmDialog } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { mapErrorsToInputs } from '@/util/forms';

const getDefaultProductFormItem = () => ({
  is_hidden: false,
});

const state = {
  products: null,
  productGroups: null,
  // productsPagination: {},
  newProduct: getDefaultProductFormItem(),
  editedProduct: {},
  productValidationErrors: {},
  productFilterParams: {},
};

const getters = {
  productGroupsSelect() {
    return [
      { id: 13, title: i18n.t('product_groups.veneered_doors'), allowed_for_new_items: false },
      { id: 14, title: i18n.t('product_groups.painted_doors'), allowed_for_new_items: false },
      {
        id: 15,
        title: i18n.t('product_groups.veneered_painted_doors'),
        allowed_for_new_items: false,
      },
      { id: 3, title: i18n.t('product_groups.door_models'), allowed_for_new_items: false },
      { id: 10, title: i18n.t('product_groups.veneered_door_models'), allowed_for_new_items: true },
      { id: 11, title: i18n.t('product_groups.painted_door_models'), allowed_for_new_items: true },
      {
        id: 12,
        title: i18n.t('product_groups.painted_veneered_door_models'),
        allowed_for_new_items: true,
      },
      // { id: 5, title: i18n.t('product_groups.veneer'), allowed_for_new_items: true },
      { id: 4, title: i18n.t('product_groups.locks'), allowed_for_new_items: true },
      // { id: 7, title: i18n.t('product_groups.accessories'), allowed_for_new_items: true },
      // { id: 8, title: i18n.t('product_groups.handles'), allowed_for_new_items: true },
      { id: 20, title: i18n.t('product_groups.sliding_door_systems'), allowed_for_new_items: true },
      { id: 21, title: i18n.t('product_groups.coverage'), allowed_for_new_items: false },
      { id: 22, title: i18n.t('product_groups.jamb_types'), allowed_for_new_items: false },
      { id: 9, title: i18n.t('product_groups.glass'), allowed_for_new_items: false },
      { id: 19, title: i18n.t('product_groups.spec_services'), allowed_for_new_items: false },
      { id: 23, title: i18n.t('product_groups.doorsteps'), allowed_for_new_items: true },
      { id: 24, title: i18n.t('product_groups.patterns'), allowed_for_new_items: true },
    ];
  },

  // productGroupsGetter() {
  //   const groups = {};
  //   for (let i = 0; i < state.productGroups.length; i++) {
  //     const parent = state.productGroups[i];
  //     if (parent.subgroup.length) {
  //       for (let j = 0; j < parent.subgroup.length; j++) {
  //         const subgroup = parent.subgroup[j];
  //         groups[subgroup.id] = subgroup;
  //       }
  //     } else {
  //       groups[parent.id] = parent;
  //     }
  //   }
  //   return groups;
  // },
  //
  // productGroupsArray(state, getters) {
  //   return Object.values(getters.productGroupsGetter);
  // },
};

const mutations = {
  SET_PRODUCTS(state, data) {
    state.products = data;
    // state.productsPagination = {
    //   current_page,
    //   per_page,
    //   total,
    // };
  },

  SET_PRODUCT_GROUPS(state, data) {
    state.productGroups = data;
  },

  SET_PRODUCT_FILTER_PARAMS(state, params) {
    state.productFilterParams = params;
  },

  STORE_PRODUCT(state, product) {
    state.products = [...state.products, product];
    state.newProduct = getDefaultProductFormItem();
    state.productValidationErrors = {};
    // state.productsPagination.total += 1;
  },

  SET_EDITED_PRODUCT(state, product) {
    state.productValidationErrors = {};
    state.editedProduct = JSON.parse(JSON.stringify(product));
  },

  UPDATE_PRODUCT(state, product) {
    state.products = updateArrayItem(state.products, product);
  },

  DELETE_PRODUCT(state, product) {
    state.products = removeArrayItem(state.products, product);
    // state.productsPagination.total -= 1;
  },

  SET_PRODUCT_VALIDATION_ERRORS(state, validationErrors) {
    state.productValidationErrors = validationErrors;
  },

  RESET_PRODUCT_VALIDATION_ERRORS(state) {
    state.productValidationErrors = {};
  },

  CLEAR_PRODUCT_VALIDATION_ERRORS(state, field) {
    delete state.productValidationErrors[field];
  },
};

const actions = {
  fetchProducts({ commit }, params) {
    commit('SET_PRODUCT_FILTER_PARAMS', params);
    return productService.getPage(params).then((res) => {
      commit('SET_PRODUCTS', res.data.products);
      commit('SET_PRODUCT_GROUPS', res.data.product_groups);
      // if (params.page !== state.productFilterParams.page) {
      //   window.scrollTo(0, 0);
      // }
    });
  },

  storeProduct({ commit }, product) {
    return productService
      .create(product)
      .then((res) => {
        commit('STORE_PRODUCT', res.data);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('product_created'));
      })
      .catch((err) => {
        commit('SET_PRODUCT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editProduct({ state, commit }, productId) {
    const product = state.products?.find((p) => p.id === productId);
    if (product) {
      commit('SET_EDITED_PRODUCT', product);
      return Promise.resolve(product);
    }
    return productService.getById(productId).then((res) => {
      commit('SET_EDITED_PRODUCT', res.data);
      return res.data;
    });
  },

  updateProduct({ commit }, product) {
    return productService
      .update(product)
      .then((res) => {
        commit('UPDATE_PRODUCT', res.data);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('product_updated'));
      })
      .catch((err) => {
        commit('SET_PRODUCT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  deleteProduct({ commit }, product) {
    openConfirmDialog({
      title: i18n.t('confirm_product_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      productService.delete(product).then(() => {
        commit('DELETE_PRODUCT', product);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('product_deleted'));
      });
    });
  },

  clearProductValidationErrors({ commit }, field) {
    commit('CLEAR_PRODUCT_VALIDATION_ERRORS', field);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
