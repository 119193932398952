<template>
  <v-app-bar id="top-app-bar" color="white" app clipped-left clipped-right elevate-on-scroll short>
    <v-app-bar-nav-icon @click.stop="toggleNavigationDrawer" />
    <img
      v-if="logoUrl"
      :src="logoUrl"
      alt="logo"
      class="clickable ml-5"
      height="30"
      @click="$router.push({ path: '/' })"
    />
    <v-spacer />

    <LanguageSelector />

    <v-menu :min-width="$isExplorer() ? '170' : ''" bottom left offset-y>
      <template v-slot:activator="{ on }">
        <v-btn large icon color="primary" v-on="on">
          <v-icon large>mdi-account-circle</v-icon>
        </v-btn>
      </template>

      <v-list subheader dense>
        <v-subheader class="text-subtitle-2">
          {{ currentUser.email }}
        </v-subheader>
        <v-divider />
        <v-list-item @click="viewProfile">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('my_profile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="impersonatingAs" @click="stopImpersonating">
          <v-list-item-icon>
            <v-icon>mdi-stop</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('stop_impersonating') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
/* eslint-disable global-require,import/no-unresolved */
import { mapState } from 'vuex';
import { getAppLogoUrl } from '@/util/company-attributes';
import LanguageSelector from './LanguageSelector';

export default {
  name: 'Toolbar',

  components: {
    LanguageSelector,
  },

  data() {
    return {
      logoUrl: getAppLogoUrl(),
    };
  },

  computed: mapState('auth', ['currentUser', 'impersonatingAs']),

  methods: {
    viewProfile() {
      this.$router.push({ name: 'profile' }).catch(() => {});
    },

    toggleNavigationDrawer() {
      this.$store.commit('settings/UPDATE_SETTING', {
        key: 'sidenavOpen',
        value: !this.$store.state.settings.sidenavOpen,
      });
    },

    stopImpersonating() {
      this.$store.dispatch('auth/stopImpersonating');
    },

    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>

<style>
.theme--dark #top-app-bar.theme--light,
.theme--light #top-app-bar.theme--light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
