import httpClient from '@/api/http-client';

const endpoint = 'api/products';

const productService = {
  getPage: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  create: (product) => httpClient.post(`${endpoint}`, product),

  update: (product) => httpClient.put(`${endpoint}/${product.id}`, product),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  delete: (product) => httpClient.delete(`${endpoint}/${product.id}`),
};

export default productService;
