import Vue from 'vue';
import userService from '../../api/user-service';
import { mapErrorsToInputs } from '../../util/forms';
import i18n from '../../i18n/i18n-config';
import eventBus, { OPEN_SNACKBAR, openConfirmDialog } from '../../util/event-bus';
import { updateArrayItem } from '../../util/array';

const getDefaultFormItem = () => ({
  person: {},
  status: 'active',
  role: 'user',
});

export const getDefaultUserFilterParams = () => ({});

const state = {
  users: null,
  userPagination: {},
  newUser: getDefaultFormItem(),
  editedUser: {},
  userValidationErrors: {},
  userFilterParams: getDefaultUserFilterParams(),
  loadingUsers: false,
  savingUser: false,
  disabledUserIds: {},
};

const getters = {
  roles() {
    return [
      { value: 'admin', text: i18n.t('user_roles.admin') },
      { value: 'user', text: i18n.t('user_roles.user') },
    ];
  },

  statuses() {
    return [
      { value: 'active', text: i18n.t('user_statuses.active') },
      { value: 'suspended', text: i18n.t('user_statuses.suspended') },
    ];
  },
};

const mutations = {
  SET_USERS(state, { data, current_page, per_page, total }) {
    state.users = data;
    state.userPagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_FILTER_PARAMS(state, params) {
    state.userFilterParams = params;
  },

  SET_EDITED_USER(state, user) {
    state.userValidationErrors = {};
    state.editedUser = JSON.parse(JSON.stringify(user));
  },

  CLEAR_VALIDATION_ERRORS(state, field) {
    delete state.userValidationErrors[field];
  },

  STORE_USER(state, user) {
    state.users = [...state.users, user];
    state.newUser = getDefaultFormItem();
    state.userValidationErrors = {};
    state.userPagination.total += 1;
  },

  UPDATE_USER(state, user) {
    state.users = updateArrayItem(state.users, user);
  },

  SET_USER_VALIDATION_ERRORS(state, userValidationErrors) {
    state.userValidationErrors = userValidationErrors;
  },

  SET_LOADING_USERS(state, loading) {
    state.loadingUsers = loading;
  },

  SET_SAVING_USER(state, saving) {
    state.savingUser = saving;
  },

  TOGGLE_DISABLED_USER(state, id) {
    if (state.disabledUserIds[id]) {
      Vue.delete(state.disabledUserIds, id);
    } else {
      Vue.set(state.disabledUserIds, id, true);
    }
  },
};

const actions = {
  fetchUsers({ state, commit }, params) {
    commit('SET_LOADING_USERS', true);
    return userService
      .getPage(params)
      .then((res) => {
        commit('SET_USERS', res.data);
        if (params.page !== state.userFilterParams.page) {
          window.scrollTo(0, 0);
        }
        commit('SET_FILTER_PARAMS', params);
      })
      .finally(() => {
        commit('SET_LOADING_USERS', false);
      });
  },

  editUser({ state, commit }, userId) {
    const user = state.users?.find((u) => u.id === userId);
    if (user) {
      commit('SET_EDITED_USER', user);
      return Promise.resolve(user);
    }
    return userService.getById(userId).then((res) => {
      commit('SET_EDITED_USER', res.data);
    });
  },

  clearUserValidationErrors({ commit }, field) {
    commit('CLEAR_VALIDATION_ERRORS', field);
  },

  storeUser({ commit }, user) {
    commit('SET_SAVING_USER', true);
    return userService
      .create(user)
      .then((res) => {
        commit('STORE_USER', res.data);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('user_created'));
      })
      .catch((err) => {
        commit('SET_USER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      })
      .finally(() => {
        commit('SET_SAVING_USER', false);
      });
  },

  updateUser({ commit }, user) {
    commit('SET_SAVING_USER', true);
    return userService
      .update(user)
      .then((res) => {
        commit('UPDATE_USER', res.data);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('user_updated'));
      })
      .catch((err) => {
        commit('SET_USER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      })
      .finally(() => {
        commit('SET_SAVING_USER', false);
      });
  },

  resetPassword({ commit }, user) {
    openConfirmDialog({
      title: i18n.t('confirm_password_reset'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      commit('TOGGLE_DISABLED_USER', user.id);
      userService
        .resetPassword(user)
        .then(() => {
          eventBus.$emit(OPEN_SNACKBAR, i18n.t('password_was_reset'));
        })
        .finally(() => {
          commit('TOGGLE_DISABLED_USER', user.id);
        });
    });
  },

  async toggleUserStatus({ commit }, user) {
    const newStatus = user.status === 'active' ? 'suspended' : 'active';
    const msg = i18n.t(`user_${newStatus === 'active' ? 'activated' : 'suspended'}`);
    commit('TOGGLE_DISABLED_USER', user.id);
    try {
      await userService.toggleStatus(user);
      commit('UPDATE_USER', {
        ...user,
        status: newStatus,
      });
      eventBus.$emit(OPEN_SNACKBAR, msg);
    } catch (e) {
      eventBus.$emit(OPEN_SNACKBAR, i18n.t('user_status_toggle_failed'));
    }
    commit('TOGGLE_DISABLED_USER', user.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
