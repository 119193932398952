import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth-module';
import settings from './modules/settings-module';
import progress from './modules/progress-module';
import users from './modules/user-module';
import clients from './modules/clients-module';
import products from './modules/products-module';
import countries from './modules/countries-module';
import contacts from './modules/contact-module';
import orders from './modules/orders-module';
import orderParts from './modules/order-parts-module';
import suppliers from './modules/suppliers-module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  mutations: {},

  actions: {},

  modules: {
    auth,
    settings,
    progress,
    users,
    clients,
    products,
    countries,
    contacts,
    orders,
    orderParts,
    suppliers,
  },
});
