/* eslint-disable implicit-arrow-linebreak */
export const updateArrayItem = (array, updatedItem, key = 'id') =>
  array.map((item) => (item[key] === updatedItem[key] ? updatedItem : item));

export const removeArrayItem = (array, deletedItem, key = 'id') =>
  array.filter((item) => item[key] !== deletedItem[key]);

export const updateArrayItemWithReference = (array, updatedItem, key = 'id') => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === updatedItem[key]) {
      array.splice(i, 1, updatedItem);
    }
  }
};

export const removeArrayItemWithReference = (array, deletedItem, key = 'id') => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === deletedItem[key]) {
      array.splice(i, 1);
    }
  }
};
