<template>
  <v-navigation-drawer v-model="$store.state.settings.sidenavOpen" app clipped>
    <v-list dense nav>
      <template v-for="(item, i) in displayedItems">
        <v-subheader v-if="item.heading" :key="i" class="text-subtitle-2">
          {{ item.heading }}
        </v-subheader>

        <v-divider v-else-if="item.divider" :key="i" class="my-2" />

        <v-list-item
          v-else
          :key="i"
          :disabled="!item.routeName"
          :to="{ name: item.routeName, query: item.query || {} }"
          active-class="primary--text"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(item.text) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list-item link @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="d-flex align-center justify-center py-4 border-t">
        <span class="mr-2 text-subtitle-2"> {{ $t('developed_by') }}: </span>
        <a :href="$t('evolco_url')" class="d-flex" target="_blank">
          <img src="../assets/logo-dark.png" alt="" height="20" />
        </a>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NavigationDrawer',

  computed: {
    ...mapState('users', ['userFilterParams']),
    ...mapState('clients', ['clientFilterParams']),
    ...mapState('products', ['productFilterParams']),
    ...mapState('orders', ['orderFilterParams']),
    ...mapState('suppliers', ['suppliersFilterParams']),

    items() {
      return [
        // {
        //   icon: 'mdi-account-multiple',
        //   text: 'users',
        //   routeName: 'users',
        //   allowedRoles: ['admin'],
        //   query: this.userFilterParams,
        // },
        {
          icon: 'mdi-cart-arrow-down',
          text: 'orders',
          routeName: 'orders',
          allowedRoles: ['admin'],
          query: this.orderFilterParams,
        },

        {
          icon: 'mdi-account-group',
          text: 'clients',
          routeName: 'clients',
          allowedRoles: ['admin'],
          query: this.clientFilterParams,
        },
        {
          icon: 'mdi-package-variant',
          text: 'products',
          routeName: 'products',
          allowedRoles: ['admin'],
          query: this.productFilterParams,
        },
        {
          icon: 'mdi-truck-delivery-outline',
          text: 'suppliers',
          routeName: 'suppliers',
          allowedRoles: ['admin'],
          query: this.suppliersFilterParams,
        },
        { divider: true, allowedRoles: ['admin'] },
        { icon: 'mdi-account', text: 'my_profile', routeName: 'profile' },
      ];
    },

    displayedItems() {
      return this.items.filter(
        (i) => !i.allowedRoles || i.allowedRoles.indexOf(this.currentUser.role) > -1,
      ); // eslint-disable-line max-len
    },

    currentUser() {
      return this.$store.getters['auth/currentUser'];
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>
