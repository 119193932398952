import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import lt from 'vuetify/es5/locale/lt';

Vue.use(Vuetify);

let settings = localStorage.getItem('settings');

if (settings) {
  settings = JSON.parse(settings);
}

const currentLang = settings?.locale || 'en';

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1a73e8',
        secondary: '#03DAC6',
        accent: '#82B1FF',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
  lang: {
    locales: { lt },
    current: currentLang,
  },
});
