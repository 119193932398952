/* eslint-disable import/no-cycle */
import store from '../store/index';

function loginGuard(to, from, next) {
  if (store.getters['auth/currentUser']) {
    return next('/');
  }
  return next();
}

function authGuard(to, from, next) {
  if (!store.getters['auth/currentUser']) {
    return next(from.path === '/' ? '/login' : false);
  }
  return next();
}

// To use this guard, specify requiredPermission in route meta.
// e.g. meta: { requiredPermission: 'can_view_clients' },
// Not used in every project though
function permissionsGuard(to, from, next) {
  if (!store.getters['auth/currentUser']) {
    return next(from.path === '/' ? '/login' : false);
  }

  if (store.getters['auth/currentUser'].permissions[to.meta.requiredPermission]) {
    return next();
  }
  return next('/');
}

// To use this guard, specify allowedRoles in route meta.
// e.g. meta: { allowedRoles: ['admin', 'manager'] },
async function roleGuard(to, from, next) {
  if (!to.meta.allowedRoles || !store.getters['auth/currentUser']) {
    return next('/login');
  }

  if (to.meta.allowedRoles.indexOf(store.getters['auth/currentUser'].role) > -1) {
    return next();
  }
  return next('/');
}

export { loginGuard, authGuard, permissionsGuard, roleGuard };
