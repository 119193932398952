import { removeArrayItem, updateArrayItem } from '@/util/array';
import suppliersService from '@/api/suppliers-service';
import eventBus, { OPEN_SNACKBAR, openConfirmDialog } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { mapErrorsToInputs } from '@/util/forms';

export const getDefaultSupplierFormItem = () => ({});

const state = {
  suppliers: null,
  newSupplier: getDefaultSupplierFormItem(),
  editedSupplier: {},
  supplierValidationErrors: {},
  suppliersFilterParams: {},
};

const getters = {
  handleSuppliers(state) {
    return state.suppliers?.filter((s) => s.responsible_for === 'handles');
  },

  veneerSuppliers(state) {
    return state.suppliers?.filter((s) => s.responsible_for === 'veneers');
  },

  glassSuppliers(state) {
    return state.suppliers?.filter((s) => s.responsible_for === 'glass');
  },

  paintSuppliers(state) {
    return state.suppliers?.filter((s) => s.responsible_for === 'paint');
  },
};

const mutations = {
  SET_SUPPLIERS(state, data) {
    state.suppliers = data;
  },

  SET_SUPPLIERS_FILTER_PARAMS(state, params) {
    state.suppliersFilterParams = params;
  },

  STORE_SUPPLIER(state, supplier) {
    state.suppliers = [supplier, ...state.suppliers];
    state.newSupplier = getDefaultSupplierFormItem();
    state.supplierValidationErrors = {};
  },

  SET_EDITED_SUPPLIER(state, supplier) {
    state.supplierValidationErrors = {};
    state.editedSupplier = JSON.parse(JSON.stringify(supplier));
  },

  UPDATE_SUPPLIER(state, supplier) {
    state.suppliers = updateArrayItem(state.suppliers, supplier);
  },

  DELETE_SUPPLIER(state, supplier) {
    state.suppliers = removeArrayItem(state.suppliers, supplier);
  },

  SET_SUPPLIER_VALIDATION_ERRORS(state, validationErrors) {
    state.supplierValidationErrors = validationErrors;
  },

  RESET_SUPPLIER_VALIDATION_ERRORS(state) {
    state.supplierValidationErrors = {};
  },

  CLEAR_SUPPLIER_VALIDATION_ERRORS(state, field) {
    delete state.supplierValidationErrors[field];
  },
};

const actions = {
  fetchSuppliers({ commit }, params) {
    commit('SET_SUPPLIERS_FILTER_PARAMS', params);
    return suppliersService.getPage(params).then((res) => {
      commit('SET_SUPPLIERS', res.data);
    });
  },

  storeSupplier({ commit }, supplier) {
    return suppliersService
      .create(supplier)
      .then((res) => {
        commit('STORE_SUPPLIER', res.data);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('supplier_created'));
      })
      .catch((err) => {
        commit('SET_SUPPLIER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editSupplier({ state, commit }, supplierId) {
    const supplier = state.suppliers?.find((s) => s.id === supplierId);
    if (supplier) {
      commit('SET_EDITED_SUPPLIER', supplier);
      return Promise.resolve(supplier);
    }
    return suppliersService.getById(supplierId).then((res) => {
      commit('SET_EDITED_SUPPLIER', res.data);
      return res.data;
    });
  },

  updateSupplier({ commit }, supplier) {
    return suppliersService
      .update(supplier)
      .then((res) => {
        commit('UPDATE_SUPPLIER', res.data);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('supplier_updated'));
      })
      .catch((err) => {
        commit('SET_SUPPLIER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  deleteSupplier({ commit }, supplier) {
    openConfirmDialog({
      title: i18n.t('confirm_supplier_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      suppliersService.delete(supplier).then(() => {
        commit('DELETE_SUPPLIER', supplier);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('supplier_deleted'));
      });
    });
  },

  clearSupplierValidationErrors({ commit }, field) {
    commit('CLEAR_SUPPLIER_VALIDATION_ERRORS', field);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
