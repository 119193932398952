export function capitalizeFirstLetter(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function pascalToSnakeCase(str) {
  return str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
}

// yyyy-MM-dd HH:mm does not work in Safari & IE11, this function fixes it by swapping '-' with '/'
export function sanitizeDateString(date) {
  if (typeof date === 'object') {
    return date;
  }
  if (!date.includes('T') || !date.includes('Z')) {
    return date.replace(/-/g, '/');
  }
  return date;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
