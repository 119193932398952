<template>
  <v-img
    :src="backgroundUrl"
    height="100%"
    width="100%"
    style="position: fixed; text-align: center"
  >
  <v-container fluid class="fill-height">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" sm="9" md="6" lg="4">
        <v-card class="elevation-12">
          <div class="text-right pr-2 pt-2">
            <LanguageSelector />
          </div>

          <v-img
            v-if="logoUrl"
            :src="logoUrl"
            height="40"
            width="300"
            class="ma-auto"
            contain
          />

          <v-form ref="form" @submit.prevent="login">
            <v-card-text>
              <v-text-field
                v-model="username"
                :label="$t('username')"
                prepend-icon="mdi-account"
                type="email"
                autofocus
              />

              <v-text-field
                v-model="password"
                :append-icon="isPasswordHidden ? 'mdi-eye' : 'mdi-eye-off'"
                :type="isPasswordHidden ? 'password' : 'text'"
                :label="$t('password')"
                prepend-icon="mdi-lock"
                @click:append="isPasswordHidden = !isPasswordHidden"
              />
            </v-card-text>

            <v-card-actions>
              <v-btn
                :loading="isRequestPending"
                :disabled="isRequestPending"
                color="primary"
                type="submit"
                block
              >
                {{ $t('login') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  </v-img>
</template>

<script>
import { getAppLogoUrl, getAppLoginBackgroundUrl } from '@/util/company-attributes';
import LanguageSelector from '../components/LanguageSelector';
import authService from '../api/auth-service';
import userService from '../api/user-service';
import eventBus, { OPEN_SNACKBAR } from '../util/event-bus';

export default {
  name: 'Login',

  components: {
    LanguageSelector,
  },

  data() {
    return {
      username: '',
      password: '',
      isPasswordHidden: true,
      isRequestPending: false,
      logoUrl: getAppLogoUrl(),
      backgroundUrl: getAppLoginBackgroundUrl(),
    };
  },

  methods: {
    async login() {
      this.isRequestPending = true;
      try {
        const loginRes = await authService.login(this.username, this.password);
        await this.$store.dispatch('auth/handleAuthData', loginRes.data);
        const userRes = await userService.getCurrent();
        await this.$store.dispatch('auth/getCurrentUser', userRes.data);
        await this.$router.push(this.$store.state.auth.lastAccessedRoute || { path: '/' });
      } catch (e) {
        if (e.response?.status === 400) {
          eventBus.$emit(OPEN_SNACKBAR, this.$t('invalid_credentials'));
        } else if (e.response?.status === 401) {
          eventBus.$emit(OPEN_SNACKBAR, this.$t('http_errors.401'));
        }
        this.isRequestPending = false;
      }
    },
  },
};
</script>
