<template>
  <v-app>
    <Toolbar v-if="currentUser" />
    <NavigationDrawer v-if="currentUser" />

    <ConfirmDialog />
    <Snackbar />
    <v-main class="transition-none">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Toolbar from './components/Toolbar';
import NavigationDrawer from './components/NavigationDrawer';
import Snackbar from './components/Snackbar';
import ConfirmDialog from './components/ConfirmDialog';

export default {
  name: 'App',

  components: {
    ConfirmDialog,
    Snackbar,
    Toolbar,
    NavigationDrawer,
  },

  computed: {
    currentUser() {
      return this.$store.getters['auth/currentUser'];
    },
  },

  created() {
    this.$store.commit('settings/LOAD_SETTINGS');
    this.addTouchListener();
    if (this.$vuetify.breakpoint.lgAndUp) {
      this.$store.commit('settings/UPDATE_SETTING', {
        key: 'sidenavOpen',
        value: true,
      });
    }
  },

  methods: {
    addTouchListener() {
      window.addEventListener('touchstart', () => {
        if (!this.isTouchDevice) {
          this.$store.commit('settings/SET_TOUCH_DEVICE');
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import './styles.scss';
@import './vuetify-customizations.scss';
</style>
