import httpClient from '@/api/http-client';

const endpoint = 'api/clients';

const clientService = {
  getPage: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  create: (client) => httpClient.post(`${endpoint}`, client),

  update: (client) => httpClient.put(`${endpoint}/${client.id}`, client),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  delete: (client) => httpClient.delete(`${endpoint}/${client.id}`),
};

export default clientService;
