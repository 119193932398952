import httpClient from './http-client';

const endpoint = 'api/users';

const userService = {
  getPage: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  getCurrent: () => httpClient.get(`${endpoint}/current`),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  create: (user) => httpClient.post(`${endpoint}`, user),

  update: (user) => httpClient.put(`${endpoint}/${user.id}`, user),

  delete: (user) => httpClient.delete(`${endpoint}/${user.id}`),

  toggleStatus: (user) => httpClient.patch(`${endpoint}/${user.id}/toggle-suspend`),

  resetPassword: (user) => httpClient.post(`${endpoint}/${user.id}/reset-password`, user),

  changeCurrentUserPassword: (payload) => httpClient.put(`${endpoint}/current/password`, payload),

  createLogin: (params) => httpClient.post(`${endpoint}/create-by-person-id/${params.id}`, params),

  destroyLogin: (contactId) => httpClient.post(`${endpoint}/delete-by-person-id/${contactId}`),

  changeContactPassword: (params) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    httpClient.post(`${endpoint}/change-pass-by-person-id/${params.id}`, params),
};

export default userService;
