import Vue from 'vue';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import ordersService from '@/api/orders-service';
import eventBus, { OPEN_SNACKBAR, openConfirmDialog } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { mapErrorsToInputs } from '@/util/forms';

const getDefaultOrderFormItem = () => ({
  is_installation_ordered: false,
  is_important: false,
  documents: [],
  parts: [],
  files: [],
  collar_cut_angle: null,
});

export const getDefaultOrderFilterParams = () => ({});

const state = {
  orders: null,
  newOrder: getDefaultOrderFormItem(),
  editedOrder: {},
  ordersPagination: {},
  orderValidationErrors: {},
  orderFilterParams: getDefaultOrderFilterParams(),
  expandedOrder: {},
};

const mutations = {
  SET_ORDERS(state, { data, current_page, per_page, total }) {
    state.orders = data;
    state.ordersPagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_ORDER_FILTER_PARAMS(state, params) {
    state.orderFilterParams = params;
  },

  SET_EDITED_ORDER(state, order) {
    state.orderValidationErrors = {};
    const editedOrder = JSON.parse(JSON.stringify(order));
    editedOrder.files = [];
    state.editedOrder = editedOrder;
  },

  STORE_ORDER(state, order) {
    state.orders = [order, ...state.orders];
    state.ordersPagination.total += 1;
    state.newOrder = getDefaultOrderFormItem();
    state.orderValidationErrors = {};
  },

  UPDATE_ORDER(state, order) {
    state.orders = updateArrayItem(state.orders, order);
    if (order.id === state.expandedOrder.id) {
      state.expandedOrder = order;
    }
    // for (let i = 0; i < state.orders.length; i++) {
    //   if (order.id === state.orders[i].id) {
    //     state.orders.splice(i, 1, order);
    //   }
    // }
  },

  SET_EXPANDED_ORDER(state, order) {
    state.expandedOrder = order;
  },

  REMOVE_ORDER_DOCUMENT(state, document) {
    const orderDocuments = removeArrayItem(state.editedOrder.documents, document);
    Vue.set(state.editedOrder, 'documents', orderDocuments);
    for (let i = 0; i < state.orders.length; i++) {
      for (let j = 0; j < state.orders[i].documents.length; j++) {
        if (state.orders[i].documents[j].id === document.id) {
          Vue.set(state.orders[i], 'documents', orderDocuments);
          break;
        }
      }
    }
  },

  DELETE_ORDER(state, order) {
    state.orders = removeArrayItem(state.orders, order);
    state.ordersPagination.total -= 1;
  },

  SET_ORDER_VALIDATION_ERRORS(state, orderValidationErrors) {
    state.orderValidationErrors = orderValidationErrors;
  },

  RESET_ORDER_VALIDATION_ERRORS(state) {
    state.orderValidationErrors = {};
  },

  CLEAR_ORDER_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.orderValidationErrors, field);
  },
};

const actions = {
  fetchOrders({ commit }, params) {
    commit('SET_ORDER_FILTER_PARAMS', params);
    return ordersService.getPage(params).then((res) => {
      commit('SET_ORDERS', res.data);
      return res.data;
    });
  },

  storeOrder({ commit }, order) {
    return ordersService
      .create(order)
      .then((res) => {
        commit('STORE_ORDER', res.data);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('order_created'));
      })
      .catch((err) => {
        commit('SET_ORDER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editOrder({ state, commit }, orderId) {
    const order = state.orders?.find((o) => o.id === orderId);
    if (order) {
      commit('SET_EDITED_ORDER', order);
      return Promise.resolve(order);
    }
    return ordersService.getById(orderId).then((res) => {
      commit('SET_EDITED_ORDER', res.data);
      return res.data;
    });
  },

  updateOrder({ state, commit }, order) {
    return ordersService
      .update(order)
      .then((res) => {
        commit('UPDATE_ORDER', res.data);
        if (state.expandedOrder.id === res.data.id) {
          commit('orderParts/SET_ORDER_PARTS', res.data.parts, { root: true });
        }
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('order_updated'));
      })
      .catch((err) => {
        commit('SET_ORDER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  deleteOrder({ commit }, order) {
    openConfirmDialog({
      title: i18n.t('confirm_order_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      ordersService.delete(order).then(() => {
        commit('DELETE_ORDER', order);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('order_deleted'));
      });
    });
  },

  clearOrderValidationErrors({ commit }, field) {
    commit('CLEAR_ORDER_VALIDATION_ERRORS', field);
  },

  exportOrders({ commit }, exportedOrder) {
    return ordersService
      .exportOrders(exportedOrder)
      .then((res) => {
        commit('SET_ORDERS', res.data);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('orders_exported'));
      })
      .catch((err) => {
        commit('SET_ORDER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  importOrders({ commit }, importedOrder) {
    return ordersService
      .importOrders(importedOrder)
      .then((res) => {
        commit('SET_ORDERS', res.data);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('orders_imported'));
      })
      .catch((err) => {
        commit('SET_ORDER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
