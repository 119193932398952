import clientService from '@/api/client-service';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import eventBus, { OPEN_SNACKBAR, openConfirmDialog } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { mapErrorsToInputs } from '@/util/forms';

export const getDefaultClientContacts = () => ({
  pivot: {
    force_spec_coefs: false,
  },
});

const getDefaultClientFormItem = () => ({
  is_active: false,
  contacts: [getDefaultClientContacts()],
});

const state = {
  clients: null,
  newClient: getDefaultClientFormItem(),
  editedClient: {},
  clientValidationErrors: {},
  clientFilterParams: {},
};

const getters = {
  importanceItems() {
    return [
      {
        label: i18n.t('vip'),
        value: 1,
      },
      {
        label: i18n.t('important'),
        value: 2,
      },
      {
        label: i18n.t('regular'),
        value: 3,
      },
    ];
  },
};

const mutations = {
  SET_CLIENTS(state, data) {
    state.clients = data;
  },

  SET_CLIENT_FILTER_PARAMS(state, params) {
    state.clientFilterParams = params;
  },

  STORE_CLIENT(state, client) {
    state.clients = [...state.clients, client];
    state.newClient = getDefaultClientFormItem();
    state.clientValidationErrors = {};
  },

  SET_EDITED_CLIENT(state, client) {
    state.clientValidationErrors = {};
    state.editedClient = JSON.parse(JSON.stringify(client));
  },

  UPDATE_CLIENT(state, client) {
    state.clients = updateArrayItem(state.clients, client);
  },

  UPDATE_CLIENT_AFTER_LOGIN_CREATE(state, contact) {
    for (let i = 0; i < state.clients.length; i++) {
      for (let j = 0; j < state.clients[i].contacts.length; j++) {
        if (state.clients[i].contacts[j].id === contact.id) {
          state.clients[i].contacts[j].user = contact.user;
        }
      }
    }
  },

  DELETE_CLIENT(state, client) {
    state.clients = removeArrayItem(state.clients, client);
  },

  SET_CLIENT_VALIDATION_ERRORS(state, validationErrors) {
    state.clientValidationErrors = validationErrors;
  },

  RESET_CLIENT_VALIDATION_ERRORS(state) {
    state.clientValidationErrors = {};
  },

  CLEAR_CLIENT_VALIDATION_ERRORS(state, field) {
    delete state.clientValidationErrors[field];
  },
};

const actions = {
  fetchClients({ commit }, params) {
    commit('SET_CLIENT_FILTER_PARAMS', params);
    return clientService.getPage(params).then((res) => {
      commit('SET_CLIENTS', res.data.data);
    });
  },

  storeClient({ commit }, client) {
    return clientService
      .create(client)
      .then((res) => {
        commit('STORE_CLIENT', res.data);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('client_created'));
      })
      .catch((err) => {
        commit('SET_CLIENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editClient({ state, commit }, clientId) {
    const client = state.clients?.find((c) => c.id === clientId);
    if (client) {
      commit('SET_EDITED_CLIENT', client);
      return Promise.resolve(client);
    }
    return clientService.getById(clientId).then((res) => {
      commit('SET_EDITED_CLIENT', res.data);
      return res.data;
    });
  },

  updateClient({ commit }, client) {
    return clientService
      .update(client)
      .then((res) => {
        commit('UPDATE_CLIENT', res.data);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('client_updated'));
      })
      .catch((err) => {
        commit('SET_CLIENT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  deleteClient({ commit }, client) {
    openConfirmDialog({
      title: i18n.t('confirm_client_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      clientService.delete(client).then(() => {
        commit('DELETE_CLIENT', client);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('client_deleted'));
      });
    });
  },

  clearClientValidationErrors({ commit }, field) {
    commit('CLEAR_CLIENT_VALIDATION_ERRORS', field);
  },
};

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
};
