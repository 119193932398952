<template>
  <v-menu :min-width="$isExplorer() ? '90' : ''" bottom offset-y left>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" text>
        <img :src="getLocaleImageUrl(selectedLocale)" height="24" class="mr-2" />
        {{ selectedLocale.toUpperCase() }}
      </v-btn>
    </template>

    <v-list class="pa-0">
      <v-list-item
        v-for="locale in $store.getters['settings/availableLocales']"
        :key="locale.value"
        :disabled="locale.disabled"
        @click="updateLocale(locale.value)"
      >
        <v-list-item-action class="mr-4">
          <img :src="getLocaleImageUrl(locale.value)" height="24" />
        </v-list-item-action>
        <v-list-item-title class="text-subtitle-2">
          {{ locale.value.toUpperCase() }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'LanguageSelector',

  computed: {
    selectedLocale() {
      return this.$store.state.settings.locale;
    },
  },

  methods: {
    getLocaleImageUrl(locale) {
      return `${process.env.VUE_APP_PUBLIC_PATH}img/${locale}.png`;
    },

    updateLocale(locale) {
      this.$store.commit('settings/UPDATE_LOCALE', locale);
      // This available options fetching is for translations, that comes from BE
      this.$store.dispatch('orderParts/fetchAvailableOptions');
    },
  },
};
</script>
