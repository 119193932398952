/* eslint-disable no-shadow,no-param-reassign,no-mixed-operators,import/no-cycle */
import router from '../../router/index';
import userService from '../../api/user-service';
import authService from '../../api/auth-service';

const state = {
  accessToken: null,
  impersonatingAs: null,
  currentUser: null,
  wasAutoLoginAttempted: false,
  lastAccessedRoute: null,
  refreshTokenPromise: null,
};

const getters = {
  currentUser(state) {
    return state.currentUser;
  },

  accessToken(state) {
    return state.accessToken;
  },

  impersonatingAs(state) {
    return state.impersonatingAs;
  },
  permissions(state) {
    const permissionMap = {};
    for (let i = 0; i < state.currentUser?.permissions.length; i++) {
      permissionMap[state.currentUser.permissions[i].permission] = true;
    }
    return permissionMap;
  },
};

const mutations = {
  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken;
  },

  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser;
  },

  SET_AUTO_LOGIN_ATTEMPT(state, status) {
    state.wasAutoLoginAttempted = status;
  },

  SET_LAST_ACCESSED_ROUTE(state, route) {
    state.lastAccessedRoute = route;
  },

  SET_REFRESH_TOKEN_PROMISE(state, promise) {
    state.refreshTokenPromise = promise;
  },

  CLEAR_AUTH_DATA(state) {
    state.accessToken = null;
    state.currentUser = null;
    state.permissions = null;
    state.impersonatingAs = null;
  },

  SET_IMPERSONATED_USER_ID(state, id) {
    state.impersonatingAs = id;
  },

  STOP_IMPERSONATING(state, payload) {
    state.currentUser = payload.impersonator;
    state.impersonatingAs = null;
  },
};

const actions = {
  handleAuthData({ commit }, payload) {
    const now = new Date();
    // refresh access token when there's less than 1/3rd left on its expiry time
    const refreshAt = new Date(now.getTime() + payload.expires_in * 666);
    commit('SET_ACCESS_TOKEN', payload.access_token);
    localStorage.setItem('accessToken', payload.access_token);
    localStorage.setItem('refreshToken', payload.refresh_token);
    localStorage.setItem('tokenRefreshDate', refreshAt.toString());
  },

  async refreshToken({ dispatch }, refreshToken) {
    try {
      const response = await authService.refreshToken(refreshToken);
      dispatch('handleAuthData', response.data);
    } catch (e) {
      dispatch('logout');
    }
  },

  async tryAutoLogin({ state, commit, dispatch }) {
    if (state.wasAutoLoginAttempted) {
      return;
    }
    commit('SET_AUTO_LOGIN_ATTEMPT', true);

    const refreshToken = localStorage.getItem('refreshToken');
    let refreshAt = localStorage.getItem('tokenRefreshDate');
    if (refreshAt) {
      refreshAt = new Date(refreshAt);
    }
    const now = new Date();
    if (refreshToken && (!refreshAt || now.getTime() >= refreshAt.getTime())) {
      await dispatch('refreshToken', refreshToken);
    } else {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        commit('SET_ACCESS_TOKEN', accessToken);
      } else {
        return;
      }
    }

    const impersonator = JSON.parse(localStorage.getItem('impersonator'));
    if (impersonator) {
      const impersonatingAs = JSON.parse(localStorage.getItem('currentUser'));
      if (impersonatingAs) {
        commit('SET_IMPERSONATED_USER_ID', impersonatingAs.id);
      } else {
        localStorage.removeItem('impersonator');
      }
    }
    await dispatch('getCurrentUser');
  },

  logout({ commit }) {
    commit('CLEAR_AUTH_DATA');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenRefreshDate');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('impersonator');
    router.replace({ name: 'login' });
  },

  async getCurrentUser({ commit, state, dispatch }) {
    let response;
    if (!state.accessToken) {
      return response;
    }
    try {
      response = await userService.getCurrent();
      commit('SET_CURRENT_USER', response.data);
      localStorage.setItem('currentUser', JSON.stringify(response.data));
    } catch (e) {
      response = e;
      dispatch('logout');
    }
    return response;
  },

  async impersonateUser({ commit, state, dispatch }, user) {
    commit('SET_IMPERSONATED_USER_ID', user.id);
    localStorage.setItem('impersonator', JSON.stringify(state.currentUser));
    await dispatch('getCurrentUser');
    window.location.href = `${process.env.VUE_APP_PUBLIC_PATH}profile`;
  },

  stopImpersonating({ commit }) {
    const impersonator = JSON.parse(localStorage.getItem('impersonator'));
    commit('STOP_IMPERSONATING', {
      impersonator,
    });
    localStorage.setItem('currentUser', JSON.stringify(impersonator));
    localStorage.removeItem('impersonator');
    window.location.href = `${process.env.VUE_APP_PUBLIC_PATH}clients`;
  },
};

const authModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default authModule;
