/* eslint-disable import/prefer-default-export */
import logo from '@/assets/logo-dark.png';
import loginBackground from '@/assets/login-bg.jpg';

export function getAppLogoUrl() {
  const { VUE_APP_CUSTOM_APP_LOGO_URL } = process.env;
  if (VUE_APP_CUSTOM_APP_LOGO_URL === '-') {
    return '';
  }
  return VUE_APP_CUSTOM_APP_LOGO_URL || logo;
}

export function getAppLoginBackgroundUrl() {
  const { VUE_APP_CUSTOM_APP_LOGIN_BACKGROUND_URL } = process.env;
  if (VUE_APP_CUSTOM_APP_LOGIN_BACKGROUND_URL === '-') {
    return '';
  }
  return VUE_APP_CUSTOM_APP_LOGIN_BACKGROUND_URL || loginBackground;
}

export function getAppTitle() {
  const { VUE_APP_CUSTOM_APP_TITLE } = process.env;
  if (VUE_APP_CUSTOM_APP_TITLE === '-') {
    return '';
  }
  return VUE_APP_CUSTOM_APP_TITLE || 'Interjero durys';
}
