/* eslint-disable no-shadow,no-param-reassign,global-require,import/no-unresolved, */

import i18n from '../../i18n/i18n-config';

const state = {
  locale: 'lt',
  isTouchDevice: false,
  sidenavOpen: false,
};

const getters = {
  availableLocales() {
    return [
      { value: 'lt', disabled: false },
      { value: 'en', disabled: false },
    ];
  },
};

const mutations = {
  // eslint-disable-next-line no-unused-vars
  LOAD_SETTINGS(state) {
    let settings = localStorage.getItem('settings');
    if (!settings) {
      return;
    }
    settings = JSON.parse(settings);
    if (settings.locale) {
      state.locale = settings.locale;
      i18n.locale = settings.locale;
    }
  },

  UPDATE_SETTING(state, payload) {
    if (payload) {
      state[payload.key] = payload.value;
    }
    localStorage.setItem('settings', JSON.stringify(state));
  },

  UPDATE_LOCALE(state, locale) {
    state.locale = locale;
    i18n.locale = locale;
    localStorage.setItem('settings', JSON.stringify(state));
  },

  SET_TOUCH_DEVICE(state) {
    state.isTouchDevice = true;
  },
};

const settings = {
  namespaced: true,
  state,
  getters,
  mutations,
};

export default settings;
