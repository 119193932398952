import httpClient from '@/api/http-client';
import getFormData from '@/util/form-data';

const endpoint = 'api/order-parts';

const getOrderPartFormData = (orderPart) => {
  const fieldBlacklist = [];

  return getFormData(orderPart, fieldBlacklist);
};

const orderPartsService = {
  getPage: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  getAvailableOptions: () => httpClient.get('api/order-part-available-options'),

  create: (orderPart) => {
    const formData = getOrderPartFormData(orderPart);
    if (!orderPart.doorstep_type) {
      formData.append('doorstep_type', null);
    }

    if (!orderPart.side_boarder_location) {
      formData.append('side_boarder_location', null);
    }
    return httpClient.post(`${endpoint}`, formData);
  },

  update: (orderPart) => {
    const formData = getOrderPartFormData(orderPart);
    formData.append('_method', 'put');
    if (!orderPart.doorstep_type) {
      formData.append('doorstep_type', null);
    }
    return httpClient.post(`${endpoint}/${orderPart.id}`, formData);
  },

  updatePaymentsAndParts: (payload) =>
    // eslint-disable-next-line
    httpClient.post(`${endpoint}/batch-update-payments-and-parts`, payload),

  generateManufacturingDocuments: (ids) =>
    // eslint-disable-next-line
    httpClient({
      url: `${endpoint}/batch-download-manufacturing-documents`,
      method: 'POST',
      responseType: 'blob',
      data: ids,
    }),

  delete: (orderPart) => httpClient.delete(`${endpoint}/${orderPart.id}`),
};

export default orderPartsService;
