import round from './numbers';

const filters = {
  currency(val) {
    return +val >= 0 ? `€${round(+val, 2).toFixed(2)}` : '-';
  },

  percentage(val) {
    return +val >= 0 ? `${round(+val, 2).toFixed(2)}%` : '-';
  },

  truncate(val, length) {
    return val && val.length > length ? `${val.slice(0, length)}...` : val;
  },
};

function registerFilters(Vue) {
  Object.keys(filters).forEach((filter) => {
    Vue.filter(filter, filters[filter]);
  });
}

export { filters };
export default registerFilters;
