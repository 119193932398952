/* eslint-disable import/no-cycle,implicit-arrow-linebreak */
import Vue from 'vue';
import VueRouter from 'vue-router';
import { authGuard, loginGuard, roleGuard } from './guards';
// import Home from '../views/Home.vue';
import Login from '../views/Login';
import store from '../store/index';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: () => ({
      name: 'orders',
      query: store.state.orders.orderFilterParams,
    }),
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: loginGuard,
  },
  {
    path: '/profile',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (profile.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // webpackChunkName says what will the import look like in the network tab.
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile'),
    beforeEnter: authGuard,
  },
  // {
  //   path: '/users',
  //   name: 'users',
  //   component: () => import(/* webpackChunkName: "users" */ '../views/users/Users'),
  //   beforeEnter: roleGuard,
  //   meta: { allowedRoles: ['admin'] },
  //   children: [
  //     {
  //       path: 'create',
  //       name: 'createUser',
  // component: () => import(/* webpackChunkName: "createUser" */ '../views/users/CreateUser'),
  //       beforeEnter: roleGuard,
  //       meta: { allowedRoles: ['admin'] },
  //     },
  //     {
  //       path: ':id/edit',
  //       name: 'editUser',
  //       component: () => import(/* webpackChunkName: "editUser" */ '../views/users/EditUser'),
  //       beforeEnter: roleGuard,
  //       meta: { allowedRoles: ['admin'] },
  //     },
  //   ],
  // },
  {
    path: '/clients',
    name: 'clients',
    component: () => import(/* webpackChunkName: "clients" */ '../views/clients/Clients'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
    children: [
      {
        path: 'create',
        name: 'createClient',
        component: () =>
          import(/* webpackChunkName: "createClient" */ '../views/clients/CreateClient'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
      },
      {
        path: ':id/edit',
        name: 'editClient',
        component: () => import(/* webpackChunkName: "editClient" */ '../views/clients/EditClient'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
      },
      {
        path: ':id/create-login',
        name: 'createLogin',
        component: () =>
          import(/* webpackChunkName: "createLogin" */ '../views/contacts/CreateContactLogin'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
      },
      {
        path: ':id/change-password',
        name: 'changePassword',
        component: () =>
          import(
            /* webpackChunkName: "changePassword" */ '../views/contacts/ChangeContactPassword'
          ),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
      },
    ],
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "clients" */ '../views/products/Products'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
    children: [
      {
        path: 'create',
        name: 'createProduct',
        component: () =>
          import(/* webpackChunkName: "createProduct" */ '../views/products/CreateProduct'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
      },
      {
        path: ':id/edit',
        name: 'editProduct',
        component: () =>
          import(/* webpackChunkName: "editProduct" */ '../views/products/EditProduct'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
      },
    ],
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/orders/Orders'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
    children: [
      {
        path: 'create',
        name: 'createOrder',
        component: () =>
          import(/* webpackChunkName: "createOrder" */ '../views/orders/CreateOrder'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
      },
      {
        path: ':id/edit',
        name: 'editOrder',
        component: () => import(/* webpackChunkName: "editOrder" */ '../views/orders/EditOrder'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
        children: [
          {
            path: 'view-document/:documentId',
            name: 'orderDocument/view',
            component: () =>
              import(
                /* webpackChunkName: "viewOrderDocument" */ '@/views/orders/ViewOrderDocument'
              ),
            beforeEnter: authGuard,
          },
        ],
      },
      {
        path: 'create-order-part',
        name: 'createOrderPart',
        component: () =>
          import(/* webpackChunkName: "createOrderPart" */ '../views/order-parts/CreateOrderPart'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
      },
      {
        path: ':id/edit-order-part',
        name: 'editOrderPart',
        component: () =>
          import(/* webpackChunkName: "editOrderPart" */ '../views/order-parts/EditOrderPart'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
        children: [
          {
            path: 'view-document/:documentId',
            name: 'orderPartDocument/view',
            component: () =>
              import(
                /* webpackChunkName: "viewOrderDocument" */ '@/views/order-parts/ViewOrderPartDocument'
              ),
            beforeEnter: authGuard,
          },
        ],
      },
      {
        path: 'export-orders',
        name: 'exportOrders',
        component: () =>
          import(/* webpackChunkName: "exportOrders" */ '../views/orders/ExportOrders'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
      },
      {
        path: 'import-orders',
        name: 'importOrders',
        component: () =>
          import(/* webpackChunkName: "importOrders" */ '../views/orders/ImportOrders'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
      },
    ],
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () => import(/* webpackChunkName: "suppliers" */ '../views/suppliers/Suppliers'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
    children: [
      {
        path: 'create',
        name: 'createSupplier',
        component: () =>
          import(/* webpackChunkName: "createSupplier" */ '../views/suppliers/CreateSupplier'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
      },
      {
        path: ':id/edit',
        name: 'editSupplier',
        component: () =>
          import(/* webpackChunkName: "editSupplier" */ '../views/suppliers/EditSupplier'),
        beforeEnter: roleGuard,
        meta: { allowedRoles: ['admin'] },
      },
    ],
  },
];

const router = new VueRouter({
  mode: process.env.VUE_APP_HTML_HISTORY_MODE ? 'history' : '',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.isPublic) {
    next();
    return;
  }
  if (!store.getters.accessToken) {
    await store.dispatch('auth/tryAutoLogin');
  }
  if (to.name !== 'login') {
    store.commit('auth/SET_LAST_ACCESSED_ROUTE', to);
  }
  next();
});

export default router;
