import httpClient from '@/api/http-client';
import getFormData from '@/util/form-data';

const endpoint = 'api/orders';

const getOrderFormData = (order) => {
  const fieldBlacklist = [];
  const formData = getFormData(order, fieldBlacklist);
  if (order.collar_cut_angle === null) {
    formData.append('collar_cut_angle', '');
  }
  return formData;
};

const ordersService = {
  getPage: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  // create: (order) => httpClient.post(`${endpoint}`, order),
  // update: (order) => httpClient.put(`${endpoint}/${order.id}`, order),

  create: (order) => {
    const formData = getOrderFormData(order);
    return httpClient.post(`${endpoint}`, formData);
  },

  update: (order) => {
    const formData = getOrderFormData(order);
    formData.append('_method', 'put');
    return httpClient.post(`${endpoint}/${order.id}`, formData);
  },

  downloadOrderAsPdf: (order) =>
    // eslint-disable-next-line
    httpClient({
      url: `${endpoint}/${order.id}/download-as-pdf`,
      method: 'GET',
      responseType: 'blob',
    }),

  delete: (order) => httpClient.delete(`${endpoint}/${order.id}`),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  exportOrders: (exportedOrder) => httpClient.post(`${endpoint}/export`, exportedOrder),

  importOrders: (importedOrder) => {
    const formData = getOrderFormData(importedOrder);
    return httpClient.post(`${endpoint}/import`, formData);
  },
};

export default ordersService;
