/* eslint-disable indent */
import Vue from 'vue';
import orderPartsService from '@/api/order-parts-service';
import {
  removeArrayItem,
  removeArrayItemWithReference,
  updateArrayItemWithReference,
} from '@/util/array';
import eventBus, { OPEN_SNACKBAR, openConfirmDialog } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { mapErrorsToInputs } from '@/util/forms';

const getDefaultOrderPartFormItem = () => ({
  door_form: 1,
  commercial_offer: false,
  is_custom_model: false,
  quantity: 1,
  files: [],
  documents: [],
  product_id: 1,
  model_id: 207,
  jamb_type: 'overlapped',
  collars: 0,
  collar_cut_angle: 90,
  packaging: 0,
  decoration_pattern_product_id: 281,
});

const state = {
  orderParts: null,
  newOrderPart: getDefaultOrderPartFormItem(),
  creatingOrderPart: false,
  editedOrderPart: {},
  // orderPartsPagination: {},
  orderPartValidationErrors: {},
  orderPartsFilterParams: {},
  availableOrderPartOptions: {},
};

const getters = {
  orderPartsStatuses(state) {
    if (!state.availableOrderPartOptions) {
      return {};
    }
    const map = {};
    for (let i = 0; i < state.availableOrderPartOptions.available_statuses.length; i++) {
      map[state.availableOrderPartOptions.available_statuses[i].id] = {
        ...state.availableOrderPartOptions.available_statuses[i],
      };
    }
    return map;
  },
};

const mutations = {
  SET_ORDER_PARTS(state, data) {
    state.orderParts = data;
    // state.orderPartsPagination = {
    //   current_page,
    //   per_page,
    //   total,
    // };
  },

  SET_AVAILABLE_ORDER_PART_OPTIONS(state, options) {
    state.availableOrderPartOptions = options;
  },

  SET_ORDER_PARTS_FILTER_PARAMS(state, params) {
    state.orderPartsFilterParams = params;
  },

  STORE_ORDER_PART(state, orderPart) {
    // state.orderParts = [...state.orderParts, orderPart];
    state.orderParts.push(orderPart);
    // state.orderPartsPagination.total += 1;
    state.newOrderPart = getDefaultOrderPartFormItem();
    state.orderPartValidationErrors = {};
  },

  SET_EDITED_ORDER_PART(state, orderPart) {
    state.orderPartValidationErrors = {};
    const editedOrderPart = JSON.parse(JSON.stringify(orderPart));
    editedOrderPart.files = [];
    state.editedOrderPart = editedOrderPart;
  },

  SET_CREATING_ORDER_PART(state, creating) {
    state.creatingOrderPart = creating;
  },

  SET_NEW_ORDER_PART(state, orderPart) {
    const newOrderPart = JSON.parse(JSON.stringify(orderPart));
    delete newOrderPart.id;
    state.newOrderPart = newOrderPart;
  },

  RESET_NEW_ORDER_PART(state) {
    state.newOrderPart = getDefaultOrderPartFormItem();
  },

  UPDATE_ORDER_PART(state, orderPart) {
    updateArrayItemWithReference(state.orderParts, orderPart);
  },

  UPDATE_ORDER_PART_PAYMENTS_AND_PARTS(state, paymentsAndParts) {
    state.orderParts.forEach((orderPart) => {
      paymentsAndParts.forEach((paymentAndPart) => {
        if (orderPart.id === paymentAndPart.id) {
          updateArrayItemWithReference(state.orderParts, paymentAndPart);
        }
      });
    });
  },

  REMOVE_ORDER_PART_DOCUMENT(state, document) {
    const orderPartDocuments = removeArrayItem(state.editedOrderPart.documents, document);
    Vue.set(state.editedOrderPart, 'documents', orderPartDocuments);
    for (let i = 0; i < state.orderParts.length; i++) {
      for (let j = 0; j < state.orderParts[i].documents.length; j++) {
        if (state.orderParts[i].documents[j].id === document.id) {
          Vue.set(state.orderParts[i], 'documents', orderPartDocuments);
          break;
        }
      }
    }
  },

  DELETE_ORDER_PART(state, orderPart) {
    removeArrayItemWithReference(state.orderParts, orderPart);
    // state.orderPartsPagination.total -= 1;
  },

  SET_ORDER_PART_VALIDATION_ERRORS(state, validationErrors) {
    state.orderPartValidationErrors = validationErrors;
  },

  RESET_ORDER_PART_VALIDATION_ERRORS(state) {
    state.orderPartValidationErrors = {};
  },

  CLEAR_ORDER_PART_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.orderPartValidationErrors, field);
  },
};

const actions = {
  // fetchOrderParts({ commit }, params) {
  //   commit('SET_ORDER_PARTS_FILTER_PARAMS', params);
  //   return orderPartsService.getPage(params).then((res) => {
  //     commit('SET_ORDER_PARTS', res.data);
  //   });
  // },

  fetchAvailableOptions({ commit }) {
    return orderPartsService.getAvailableOptions().then((res) => {
      commit('SET_AVAILABLE_ORDER_PART_OPTIONS', res.data);
    });
  },

  storeOrderPart({ commit }, orderPart) {
    return orderPartsService
      .create(orderPart)
      .then((res) => {
        commit('STORE_ORDER_PART', res.data.data[0]);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('order_part_added'));
      })
      .catch((err) => {
        commit('SET_ORDER_PART_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editOrderPart({ state, commit }, orderPartId) {
    const orderPart = state.orderParts?.find((o) => o.id === orderPartId);
    if (orderPart) {
      commit('SET_EDITED_ORDER_PART', orderPart);
      return Promise.resolve(orderPart);
    }
    return orderPartsService.getById(orderPartId).then((res) => {
      commit('SET_EDITED_ORDER_PART', res.data);
      return res.data;
    });
  },

  updateOrderPart({ commit }, orderPart) {
    return orderPartsService
      .update(orderPart)
      .then((res) => {
        commit('UPDATE_ORDER_PART', res.data.data[0]);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('order_part_updated'));
      })
      .catch((err) => {
        commit('SET_ORDER_PART_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  updatePaymentsAndParts({ commit }, payload) {
    return orderPartsService
      .updatePaymentsAndParts(payload)
      .then((res) => {
        commit('UPDATE_ORDER_PART_PAYMENTS_AND_PARTS', res.data.data);
        eventBus.$emit(
          OPEN_SNACKBAR,
          i18n.t(
            // eslint-disable-next-line no-nested-ternary
            payload.attribute === 'status'
              ? 'order_part_status_updated'
              : // eslint-disable-next-line
              payload.attribute === 'locks_ordered'
              ? 'locks_status_updated'
              : payload.attribute === 'glass_ordered'
              ? 'glass_status_updated'
              : 'payment_status_updated',
          ),
        );
      })
      .catch((err) => {
        throw err;
      });
  },

  deleteOrderPart({ commit }, orderPart) {
    openConfirmDialog({
      title: i18n.t('confirm_order_part_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      orderPartsService.delete(orderPart).then(() => {
        commit('DELETE_ORDER_PART', orderPart);
        eventBus.$emit(OPEN_SNACKBAR, i18n.t('order_part_deleted'));
      });
    });
  },

  clearOrderPartValidationErrors({ commit }, field) {
    commit('CLEAR_ORDER_PART_VALIDATION_ERRORS', field);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
