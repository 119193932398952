/* eslint-disable no-param-reassign,no-restricted-globals */
import store from '../store';
import { capitalizeFirstLetter } from './strings';

const isAdmin = () => {
  if (store.getters['auth/currentUser']) {
    return store.getters['auth/currentUser'].role === 'admin';
  }
  return false;
};

const isUser = () => {
  if (store.getters['auth/currentUser']) {
    return store.getters['auth/currentUser'].role === 'user';
  }
  return false;
};

export const helpers = {
  $isAdmin: isAdmin,
  $isUser: isUser,

  $isCurrentUser(id) {
    if (store.getters['auth/currentUser']) {
      return store.getters['auth/currentUser'].id === id;
    }
    return false;
  },

  $isExplorer() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('Trident');

    return msie > -1;
  },

  $capitalizeFirstLetter(s) {
    return capitalizeFirstLetter(s);
  },

  $backendUrl(path) {
    return process.env.VUE_APP_API_ENDPOINT + path;
  },

  $previewBackendUrl(path) {
    return `${this.$backendUrl(path)}page_preview_key=${process.env.VUE_APP_PAGE_PREVIEW_KEY}`;
  },

  $getProperty(object, propertyName) {
    const parts = propertyName.split('.');
    let property = object || this;

    for (let i = 0; i < parts.length; i += 1) {
      if (property) {
        property = property[parts[i]];
      }
    }

    return property;
  },

  $canPreviewFile(extension) {
    const supportedExtensions = ['png', 'jpeg', 'jpg', 'gif', 'bmp'];
    if (
      !/(iPad|iPhone|iPod|Trident|Android)/g.test(navigator.userAgent) &&
      !/(Apple)/g.test(navigator.vendor)
    ) {
      // not an iOS browser, not Android browser, not Safari, not IE
      supportedExtensions.push('pdf');
    }
    return supportedExtensions.includes(extension?.trim().toLowerCase());
  },

  $customAppTitle() {
    return process.env.VUE_APP_CUSTOM_APP_TITLE;
  },
};

function registerHelpers(Vue) {
  Object.keys(helpers).forEach((helper) => {
    Vue.prototype[helper] = helpers[helper];
  });
}

export default registerHelpers;
